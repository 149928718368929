<template>
	<div class="d-flex justify-center align-center">
		<!-- prepend-icon="fas fa-plus"
			append-icon="fas fa-minus" -->
		<v-slider
			v-if="isOpened.layersrasterSettings"
			v-model="opacity"
			@change="changeOpacityLayer(opacity)"
			class="ml-1"
			style="min-width: 70%"
			:disabled="!isRasterActive"
			:color="color"
			:max="100"
			:min="0"
			step="10"
		>
			<template v-slot:prepend>
				<v-icon class="fak fa-line-less-zoom fw-500 pt-1" size="16" :color="color" @click="decrement" :disabled="!isRasterActive"></v-icon>
			</template>

			<template v-slot:append>
				<v-icon class="fak fa-line-more-zoom fw-500 pt-1" size="16" :color="color" @click="increment" :disabled="!isRasterActive"></v-icon>
			</template>
		</v-slider>

		<v-slider
			v-if="isOpenedCompareMap1.layersrasterCompareMap1"
			v-model="opacityCompareMap1"
			@change="changeOpacityLayer(opacityCompareMap1)"
			class="ml-1"
			style="min-width: 70%"
			:disabled="!isRasterCompareMap1Active"
			:color="color"
			:max="100"
			:min="0"
			step="10"
		>
			<template v-slot:prepend>
				<v-icon
					class="fak fa-line-less-zoom fw-500 pt-1"
					size="16"
					:color="color"
					@click="decrementCompareMap1"
					:disabled="!isRasterCompareMap1Active"
				></v-icon>
			</template>

			<template v-slot:append>
				<v-icon
					class="fak fa-line-more-zoom fw-500 pt-1"
					size="16"
					:color="color"
					@click="incrementCompareMap1"
					:disabled="!isRasterCompareMap1Active"
				></v-icon>
			</template>
		</v-slider>

		<v-slider
			v-if="isOpenedCompareMap2.layersrasterCompareMap2"
			v-model="opacityCompareMap2"
			@change="changeOpacityLayer(opacityCompareMap2)"
			class="ml-1"
			style="min-width: 70%"
			:disabled="!isRasterCompareMap2Active"
			:color="color"
			:max="100"
			:min="0"
			step="10"
		>
			<template v-slot:prepend>
				<v-icon
					class="fak fa-line-less-zoom fw-500 pt-1"
					size="16"
					:color="color"
					@click="decrementCompareMap2"
					:disabled="!isRasterCompareMap2Active"
				></v-icon>
			</template>

			<template v-slot:append>
				<v-icon
					class="fak fa-line-more-zoom fw-500 pt-1"
					size="16"
					:color="color"
					@click="incrementCompareMap2"
					:disabled="!isRasterCompareMap2Active"
				></v-icon>
			</template>
		</v-slider>
	</div>
</template>

<script>
import ol2map from './ol2map';

export default {
	data() {
		return {
			color: '#014efa'
		};
	},
	computed: {
		isOpened() {
			return this.$store.state.settingsPanel.isOpened;
		},
		isOpenedCompareMap1() {
			return this.$store.state.compareMapPanel.isOpened;
		},
		isOpenedCompareMap2() {
			return this.$store.state.compareMapPanel.isOpened;
		},
		opacity: {
			get() {
				return this.$store.state.mappanel.rasterOpacity;
			},
			set(value) {
				this.$store.commit('setRasterOpacity', value);
			}
		},
		opacityCompareMap1: {
			get() {
				return this.$store.state.mappanel.rasterOpacityCompareMap1;
			},

			set(value) {
				this.$store.commit('setRasterOpacityCompareMap1', value);
			}
		},
		opacityCompareMap2: {
			get() {
				return this.$store.state.mappanel.rasterOpacityCompareMap2;
			},

			set(value) {
				this.$store.commit('setRasterOpacityCompareMap2', value);
			}
		},
		isRasterActive: {
			get() {
				return this.$store.getters.isRasterActive;
			},
			set(value) {
				this.$store.commit('setIsRasterActive', value);
			}
		},
		isRasterCompareMap1Active: {
			get() {
				return this.$store.getters.isRasterCompareMap1Active;
			},
			set(value) {
				this.$store.commit('setIsRasterCompareMap1Active', value);
			}
		},
		isRasterCompareMap2Active: {
			get() {
				return this.$store.getters.isRasterCompareMap2Active;
			},
			set(value) {
				this.$store.commit('setIsRasterCompareMap2Active', value);
			}
		}
		/*activeRasterLayer: {
			get() {
				return  this.$store.getters.getActiveRasterLayer;
			},
			set(value) {
				this.$store.commit('setActiveRasterLayer', value);
			}
		},
		activeCompareMap1RasterLayer: {
			get() {
				return  this.$store.getters.getActiveCompareMap1RasterLayer;
			},
			set(value) {
				this.$store.commit('setActiveCompareMap1RasterLayer', value);
			}
		},
		activeCompareMap2RasterLayer: {
			get() {
				return  this.$store.getters.getActiveCompareMap2RasterLayer;
			},
			set(value) {
				this.$store.commit('setActiveCompareMap2RasterLayer', value);
			}
		}*/
	},
	methods: {
		changeOpacityLayer(value) {
			var layer = ol2map.getRasterActiveLayer();
			ol2map.setOpacity(layer.values_.id, value * 0.01);
		},
		decrement() {
			this.opacity -= 10;
			this.changeOpacityLayer(this.opacity);
		},
		increment() {
			this.opacity += 10;
			this.changeOpacityLayer(this.opacity);
		},
		decrementCompareMap1() {
			this.opacityCompareMap1 -= 10;
			this.changeOpacityLayer(this.opacityCompareMap1);
		},
		incrementCompareMap1() {
			this.opacityCompareMap1 += 10;
			this.changeOpacityLayer(this.opacityCompareMap1);
		},
		decrementCompareMap2() {
			this.opacityCompareMap2 -= 10;
			this.changeOpacityLayer(this.opacityCompareMap2);
		},
		incrementCompareMap2() {
			this.opacityCompareMap2 += 10;
			this.changeOpacityLayer(this.opacityCompareMap2);
		}
	}
};
</script>
<style lang="postcss">
.v-slider.v-slider--horizontal {
	cursor: pointer !important;
}
.v-slider__track-container {
	height: 4px !important;
}
.v-messages {
	min-height: auto !important;
}
</style>
