const layerProperties = {
	layerName: null,
	type: 'WMS',
	url: '/geoserver/eiffel/wms',
	version: '2.21.2',
	format: 'image/png',
	srs: 'EPSG:4326',
	serverType: 'geoserver',
	crossOrigin: 'anonymous',
	ratio: 1,
	getinfo: {
		active: true,
		infoformat: 'text/html'
	}
};

export default layerProperties;
