<template>
	<div class="layerslist d-flex flex-column pa-0" style="width: 100%">
		<div>
			<div class="mt-1 subheader">
				<!-- <p>{{ $t('rasterTools.tools') }}</p>
				<map-get-feature-info />
				-->
				<div v-if="!isActiveTabCompareMaps" class="raster-tools d-flex">
					<map-get-feature-info />

					<MapGetAQI v-if="showAQI" />
					<div v-if="isSatelliteLayers">
						<map-get-satellite-predictions
							v-for="(layer, i) in satelliteLayers"
							:key="i"
							:satelliteLayer="layer"
						></map-get-satellite-predictions>
					</div>
				</div>

				<div>
					<div class="d-flex justify-space-between align-center mt-3 mb-2">
						<p class="ma-0 fw-bold fs-regular color-black">{{ $t('rasterTools.opacity') }}</p>
						<span class="fw-bold fs-regular" v-if="isOpened.layersrasterSettings && isRasterActive">{{ opacity }}%</span>
						<span class="fw-bold fs-regular" v-if="isOpenedCompareMap1.layersrasterCompareMap1 && isRasterCompareMap1Active"
							>{{ opacityCompareMap1 }}%</span
						>
						<span class="fw-bold fs-regular" v-if="isOpenedCompareMap2.layersrasterCompareMap2 && isRasterCompareMap2Active"
							>{{ opacityCompareMap2 }}%</span
						>
					</div>
					<map-opacity-control />
				</div>

				<!-- Line separator for the tools -->
				<!--
					<span class="ml-2 mr-2">|</span>
					<map-get-feature-info :isMapLoaded="mapLoaded" />
					<span class="ml-2 mr-2">|</span>
				-->
			</div>
		</div>
	</div>
</template>

<script>
import MapGetFeatureInfo from '@/components/map/sections/map/subcomponents/MapGetFeatureInfo';
import MapOpacityControl from '@/components/map/sections/map/subcomponents/MapOpacityControl';
import MapGetAQI from '@/components/map/sections/map/subcomponents/MapGetAQI';
import MapGetSatellitePredictions from '@/components/map/sections/map/subcomponents/MapGetSatellitePredictions';

import constants from '@/utils/constants';
import { COMPAREMAPS_PANEL_ID } from '@/lib/variables/panels';

export default {
	name: 'layerstoolsection',

	components: {
		MapGetFeatureInfo,
		MapOpacityControl,
		MapGetAQI,
		MapGetSatellitePredictions
	},

	data() {
		return {
			/* isRasterActive: false, */
		};
	},

	props: {},
	computed: {
		isOpened() {
			return this.$store.state.settingsPanel.isOpened;
		},
		isOpenedCompareMap1() {
			return this.$store.state.compareMapPanel.isOpened;
		},
		isOpenedCompareMap2() {
			return this.$store.state.compareMapPanel.isOpened;
		},

		isActiveTabCompareMaps() {
			return this.$store.getters.activePanel === COMPAREMAPS_PANEL_ID;
		},
		mapLoaded() {
			return this.$store.state.map.mapLoaded;
		},
		opacity() {
			return this.$store.state.mappanel.rasterOpacity;
		},
		opacityCompareMap1() {
			return this.$store.state.mappanel.rasterOpacityCompareMap1;
		},
		opacityCompareMap2() {
			return this.$store.state.mappanel.rasterOpacityCompareMap2;
		},
		isRasterActive: {
			get() {
				return this.$store.getters.isRasterActive;
			},
			set(value) {
				this.$store.commit('setIsRasterActive', value);
			}
		},
		isRasterCompareMap1Active: {
			get() {
				return this.$store.getters.isRasterCompareMap1Active;
			},
			set(value) {
				this.$store.commit('setIsRasterCompareMap1Active', value);
			}
		},
		isRasterCompareMap2Active: {
			get() {
				return this.$store.getters.isRasterCompareMap2Active;
			},
			set(value) {
				this.$store.commit('setIsRasterCompareMap2Active', value);
			}
		},
		activeRasterLayer: {
			get() {
				return this.$store.getters.getActiveRasterLayer;
			},
			set(value) {
				this.$store.commit('setActiveRasterLayer', value);
			}
		},

		activeCompareMap1RasterLayer: {
			get() {
				return this.$store.getters.getActiveCompareMap1RasterLayer;
			},
			set(value) {
				this.$store.commit('setActiveCompareMap1RasterLayer', value);
			}
		},
		activeCompareMap2RasterLayer: {
			get() {
				return this.$store.getters.getActiveCompareMap2RasterLayer;
			},
			set(value) {
				this.$store.commit('setActiveCompareMap2RasterLayer', value);
			}
		},
		isSatelliteLayers() {
			return this.satelliteLayers.length > 0;
		},
		satelliteLayers() {
			return Object.values(this.$store.getters.layers).filter((layer) => layer.model == constants.SATELLITE_MODEL);
			//return this.$store.getters.layers;
		},
		showAQI() {
			return !!this.session.showaqi;
		}
	},

	mounted() {
		//console.log('debug raster tools', this.isSatelliteLayers, this.satelliteLayers, Object.keys(this.satelliteLayers).length);
	},

	watch: {
		'$store.state.mappanel.rasterLayersSettings': {
			// eslint-disable-next-line no-unused-vars
			handler() {
				if (this.$store.state.settingsPanel.isOpened.layersrasterSettings) {
					if (this.activeRasterLayer) {
						this.isRasterActive = true;
					} else {
						this.isRasterActive = false;
					}
				}
			},
			deep: true
		},
		'$store.state.mappanel.rasterLayersCompareMap1': {
			handler() {
				if (this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap1) {
					if (this.activeCompareMap1RasterLayer) {
						this.isRasterCompareMap1Active = true;
					} else {
						this.isRasterCompareMap1Active = false;
					}
				}
			},
			deep: true
		},
		'$store.state.mappanel.rasterLayersCompareMap2': {
			handler() {
				if (this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap2) {
					if (this.activeCompareMap2RasterLayer) {
						this.isRasterCompareMap2Active = true;
					} else {
						this.isRasterCompareMap2Active = false;
					}
				}
			},
			deep: true
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/eiffel-variables.pcss';

.raster-tools {
	min-width: 100%;
}

.raster-tools-btn {
	background-color: var(--gris-50);
	border-radius: 8px;

	&.active {
		background-color: var(--azul-primary-100);
	}
}
</style>
