<template>
	<v-btn icon @click="showAQI" ref="AQI_values" id="aqi-values-btn" class="raster-tools-btn pa-2 ma-1">
		<v-icon class="fak fa-aqi fs-lg" :color="visible ? '#fff' : 'var(--gris-primary-active)'"></v-icon>
		<!-- <img v-if="!visible" src="@/assets/aqi/airQuality.svg" :title="aqiTool" />
		<img v-else src="@/assets/aqi/airQuality-white.svg" :title="aqiTool" /> -->

		<!--<a :data-tooltip="aqiTool" />-->
		<!--<div :data-tooltip="toolInfoBypixel" src="@/assets/aqi/airQuality.svg">-->
		<!--<img src="@/assets/aqi/airQuality.svg" />-->
		<!--<v-icon class="fw-bold" size="10">fas fa-info</v-icon>-->
		<!--<v-icon>
				<aqiIcon />
			</v-icon>-->
		<!--</div>-->
		<detail :dialog.sync="visible" @update:dialog="visible = false" />
	</v-btn>
</template>

<script>
import { toggleActiveClass } from '@/api/common';
import detail from './aqi/detail';

export default {
	name: 'mapgetaqi',

	data() {
		return {
			aqiTool: '',
			visible: false
		};
	},

	components: {
		detail
	},
	computed: {},

	watch: {
		visible: {
			handler(newValue, oldValue) {
				console.log('visible from', oldValue, ' to ', newValue);
				toggleActiveClass('aqi-values-btn', newValue);
			}
		}
	},

	mounted() {
		this.aqiTool = this.$t('rasterTools.aqiValues');
		document.addEventListener('keydown', this.hideDialog);
	},

	methods: {
		showAQI() {
			this.visible = true;
		},
		hideDialog(e) {
			console.log('hideDialog', e.key, this.visible);
			if (e.key == 'Escape' && this.visible == true) this.visible = false;
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/eiffel-variables.pcss';

img[title]::before {
	/* needed - do not touch */
	content: attr(title);
	position: absolute;
	opacity: 0;

	/* customizable */
	transition: all 0.15s ease;
	padding: 1px;
	color: white;
	border-radius: 10px;
	box-shadow: 2px 2px 1px silver;
}

img[title]:hover::before {
	/* needed - do not touch */
	opacity: 1;

	/* customizable */
	background: #014efa;
	margin-top: -50px;
	margin-left: -10px;

	white-space: pre-wrap;
	width: 400px;
}

img[title]:not([title])::before {
	pointer-events: none;
}
</style>
