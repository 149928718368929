<template>
	<div class="pt-0 w-100">
		<v-form v-model="aqiForm" ref="form" style="max-height: 91px; overflow: hidden auto">
			<div row wrap>
				<v-layout wrap class="px-3" justify-center>
					<v-flex xs12 md10>
						<pui-select
							:label="$t('aqi.dateInterval')"
							v-model="form.chooseDate"
							:items="filter_options"
							:itemValue="['value']"
							itemText="text"
							toplabel
							required
							@input="changedLabel"
						>
						</pui-select>
					</v-flex>
				</v-layout>
			</div>
		</v-form>

		<div ref="echart" style="height: 530px; width: 100%" />
	</div>
</template>

<script>
import * as echarts from 'echarts';

import { search } from '@/api/common';

export default {
	name: 'heatmap',
	data() {
		return {
			width: '100%',
			height: '100%',
			chart: null,
			form: {
				chooseDate: '0'
			},
			filter_options: [
				{ text: this.$t('aqi.chooseInterval'), value: '0' },
				{ text: this.$t('aqi.last24h'), value: '1' },
				{ text: this.$t('aqi.last48h'), value: '2' },
				{ text: this.$t('aqi.lastWeek'), value: '7' },
				{ text: this.$t('aqi.last15D'), value: '15' },
				{ text: this.$t('aqi.lastMonth'), value: '30' }
			],
			aqiForm: false,
			xData: [],
			yData: []
		};
	},
	props: {
		resized: {
			type: Object,
			required: false
		}
	},

	computed: {
		defaultWidth() {
			if (this.resized && this.resized.width) {
				//return `${this.resized.width - 50}px`;
			}
			return '100%';
		},
		defaultHeight() {
			if (this.resized && this.resized.height) {
				return `${this.resized.height - 50}px`;
			}
			return '100%';
		}
	},

	watch: {
		resized: {
			handler() {
				this.onResize();
			}
		}
	},

	beforeDestroy() {
		if (!this.chart) {
			return;
		}
		this.chart.dispose();
		this.chart = null;
	},
	methods: {
		onResize() {
			if (this.chart) {
				this.chart.resize();
			}
		},

		changedLabel(evt) {
			// console.log(evt);
			evt = evt.value;
			if (evt != 0) {
				var currentDate = new Date();
				console.log(currentDate);

				currentDate.setDate(currentDate.getDate() - evt);
				console.log(currentDate);
				//console.log(currentDate.toISOString());

				var day = currentDate.getDate();

				var month = currentDate.getMonth() + 1;

				var year = currentDate.getFullYear();

				var filterDate = year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
				console.log(filterDate);
				this.getData(filterDate);
			} else {
				this.$puiNotify.info(this.$t('aqi.noValidOption'));
				if (!this.chart) {
					return;
				}
				this.chart.dispose();
				this.chart = null;
			}
		},

		//date.toISOString()

		async getData(date) {
			const filterByDate = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'date', op: 'ge', data: date },
					{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }
				]
			};
			//contains cn

			const body = {
				model: 'pmaqivalues',
				filter: filterByDate,
				rows: 9999 //Para obtener todos los registros
			};

			console.log(body);
			console.log(filterByDate);

			const { data } = await search(body);
			console.log('RESULT (AQI values)');
			console.log(data.data);

			if (data.data.length === 0) {
				console.log('Array is empty!');
				this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
				if (!this.chart) {
					return;
				}
				this.chart.dispose();
				this.chart = null;
			} else {
				var chartData = this.createData(data.data);

				this.initChart(chartData);
				//ejeY, ejeX, level
			}
		},

		createData(data) {
			var genData = [];

			var xAxis = [];
			var yAxis = [];
			data.forEach((item) => {
				//console.log(item);
				genData.push([item['locationname'], item['date'].slice(0, 10), item['pmaqidescriptionid']]);

				if (!xAxis.includes(item['date'].slice(0, 10))) {
					xAxis.push(item['date'].slice(0, 10));
				}
				if (!yAxis.includes(item['locationname'])) {
					yAxis.push(item['locationname']);
				}
			});
			console.log('Data ready to be loaded');
			console.log(genData);

			this.xData = xAxis;
			this.yData = yAxis;

			console.log('X Axis');
			console.log(this.xData);

			console.log('Y Axis');
			console.log(this.yData);

			return genData;
		},

		initChart(chartData) {
			this.chart = echarts.init(this.$refs['echart']);

			//-----------------------
			var option;

			// prettier-ignore
			const days = this.xData
			console.log(days);

			// prettier-ignore
			const stations = this.yData
			console.log(stations);

			// prettier-ignore
			const data = chartData.map(function (item) {
				return [item[1], item[0], item[2] || '-'];
			});

			option = {
				tooltip: {
					position: 'top'
				},
				grid: {
					height: '80%',
					top: '10%'
				},
				xAxis: {
					type: 'category',
					data: days,
					splitArea: {
						show: true
					}
				},
				yAxis: {
					type: 'category',
					data: stations,
					splitArea: {
						show: true
					}
				},
				visualMap: {
					min: 1,
					max: 6,
					calculable: true,
					orient: 'vertical',
					left: 'right',
					bottom: '50%',
					inRange: {
						color: ['#38A2CE', '#32A15E', '#F1E549', '#C83441', '#6E161D', '#A25BA4'] //From smaller to bigger value ->
					}
				},
				series: [
					{
						name: 'AQI',
						type: 'heatmap',
						data: data,
						label: {
							show: true
						}
						/*emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}*/
					}
				]
			};
			//-----------------------
			option && this.chart.setOption(option);
			//this.chart.setOption(option);
		} //end initChart
	}
};
</script>

<style scoped>
.topRight {
	position: absolute;
	top: 0px;
	right: 0px;
}
</style>
