<template>
	<v-tooltip top max-width="310" :color="primaryColor">
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				icon
				@click="showFeatureDialog(satelliteLayer)"
				:id="satelliteLayer.identifier"
				class="raster-tools-btn pa-1 ma-1"
				v-bind="attrs"
				v-on="on"
			>
				<v-icon class="fw-bold fak fa-line-future" :color="isSatelliteToolActive ? '#fff' : 'var(--gris-primary-active)'" size="16"></v-icon>
			</v-btn>
		</template>
		<span class="fs-regular">{{ satelliteLayer.title }}</span>
	</v-tooltip>
</template>

<script>
import constants from '@/utils/constants';
//import { CARD_HEIGHT } from '@/lib/variables/panels';
import { toggleActiveClass } from '@/api/common';

export default {
	name: 'MapGetSatellitePredictions',

	props: {
		satelliteLayer: {
			type: Object,
			required: false
		}
	},

	data() {
		return {
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	computed: {
		featureClick: {
			get() {
				return this.$store.getters.getFeatureClick;
			},
			set(value) {
				this.$store.commit('setFeatureClick', value);
			}
		},
		selectedTabDevice: {
			get() {
				return this.$store.getters.getSelectedTabDevice;
			},
			set(value) {
				this.$store.commit('setSelectedTabDevice', value);
			}
		},
		selectedTabHistoric: {
			get() {
				return this.$store.getters.getSelectedTabHistoric;
			},
			set(value) {
				this.$store.commit('setSelectedTabHistoric', value);
			}
		},
		selectedTabNotification: {
			get() {
				return this.$store.getters.getSelectedTabNotification;
			},
			set(value) {
				this.$store.commit('setSelectedTabNotification', value);
			}
		},
		realTimeElasticData: {
			get() {
				return this.$store.getters.getRealTimeElasticData;
			},
			set(value) {
				this.$store.commit('setRealTimeElasticData', value);
			}
		},
		displayMode: {
			get() {
				return this.$store.getters.getDisplayMode;
			},
			set(value) {
				this.$store.commit('setDisplayMode', value);
			}
		},
		isSatelliteToolActive() {
			return (
				this.featureClick ==
				Object.values(this.$store.getters.getDevicesData).find(
					(device) => device.pmdatasourcetypeid == this.satelliteLayer.pmdatasourcetypeid
				)
			);
		}
	},
	watch: {
		isSatelliteToolActive: {
			handler(newValue, oldValue) {
				console.log('debug sat tool', oldValue, newValue);
				toggleActiveClass(this.satelliteLayer.identifier, newValue);
			}
		}
	},
	mounted() {
		console.log(
			'debug satellite layer',
			this.satelliteLayer,
			Object.values(this.$store.getters.getDevicesData),
			Object.values(this.$store.getters.getDevicesData).find((device) => device.pmdatasourcetypeid == this.satelliteLayer.pmdatasourcetypeid)
		);
	},
	destroyed() {},
	methods: {
		showFeatureDialog(satelliteLayer) {
			const feature = Object.values(this.$store.getters.getDevicesData).find(
				(device) => device.pmdatasourcetypeid == satelliteLayer.pmdatasourcetypeid
			);
			console.log('debug Open form', feature, this.featureClick);
			if (feature) {
				if (this.featureClick != feature) {
					this.featureClick = feature;
					this.selectedTabDevice = 0;
					this.selectedTabHistoric = 0;
					this.selectedTabNotification = 0;
					this.realTimeElasticData = [];
					this.actionsToggled = false;

					// Define feature type only when opening the form
					if (Object.hasOwn(feature, constants.NOTIFICATIONS_ID)) {
						this.$store.commit('setFeatureType', constants.FEATURE_TYPE_NOTIFICATION);
					} else {
						this.$store.commit('setFeatureType', constants.FEATURE_TYPE_DEVICE);
					}

					/* if (document.getElementsByClassName('card-content-container')[0]) {
						document.getElementsByClassName('card-content-container')[0].style.display = 'block';
					}
					document.documentElement.style.setProperty('--map-control-bottom-position', CARD_HEIGHT + 'px'); */
					this.displayMode = 'dialog';
				} else {
					this.closeFeature();
				}
			} else {
				this.featureClick = false;
				document.documentElement.style.setProperty('--map-control-bottom-position', '0px');
			}
		},
		closeFeature() {
			this.featureClick = null;
			document.documentElement.style.setProperty('--map-control-bottom-position', '0px');
			this.displayMode = 'card';
			this.$store.commit('cleanFormHistoric');
			console.warn('formHistoric close', this.$store.getters.getHistoricStore);
		}
	}
};
</script>

<style lang="postcss"></style>
