<template>
	<div class="pt-0 w-100">
		<v-form v-model="aqiForm" ref="form" style="max-height: 91px; overflow: hidden auto">
			<div row wrap>
				<v-layout wrap class="px-3">
					<!-- Points -->
					<v-flex xs12 md6>
						<pui-select
							:label="$t('aqi.measurementPoints')"
							v-model="form.choosePoint"
							@input="getPoint"
							:items="points"
							:itemValue="['value']"
							itemText="text"
							toplabel
							required
						>
						</pui-select>
					</v-flex>
					<!-- Date interval -->
					<v-flex xs12 md6>
						<pui-select
							:label="$t('aqi.dateInterval')"
							v-model="form.chooseDate"
							:items="filter_options"
							:itemValue="['value']"
							itemText="text"
							toplabel
							required
							@input="changedLabel"
							:disabled="pointNoSelected"
						>
						</pui-select>
					</v-flex>
				</v-layout>
			</div>
		</v-form>
		<!--  echarts component -->
		<!--<LineChart :xData="xData" :series="series" />-->
		<div ref="echart" style="height: 530px; width: 100%" />
	</div>
</template>

<script>
import * as echarts from 'echarts';

import { search } from '@/api/common';

export default {
	name: 'aqiChart',
	data() {
		return {
			width: '100%',
			height: '100%',
			chart: null,
			pointNoSelected: true,
			form: {
				chooseDate: '0',
				choosePoint: '0'
			},
			filter_options: [
				{ text: this.$t('aqi.chooseInterval'), value: '0' },
				{ text: this.$t('aqi.last24h'), value: '1' },
				{ text: this.$t('aqi.last48h'), value: '2' },
				{ text: this.$t('aqi.lastWeek'), value: '7' },
				{ text: this.$t('aqi.last15D'), value: '15' },
				{ text: this.$t('aqi.lastMonth'), value: '30' }
			],
			points: [],
			aqiForm: false,
			xData: [],
			series: []
		};
	},
	components: {},
	props: {
		resized: {
			type: Object,
			required: false
		}
	},
	computed: {
		defaultWidth() {
			if (this.resized && this.resized.width) {
				//return `${this.resized.width - 50}px`;
			}
			return '100%';
		},
		defaultHeight() {
			if (this.resized && this.resized.height) {
				return `${this.resized.height - 50}px`;
			}
			return '100%';
		}
	},

	mounted() {
		this.getAQIPoints();
	},

	watch: {
		resized: {
			handler() {
				this.onResize();
			}
		}
	},

	beforeDestroy() {
		if (!this.chart) {
			return;
		}
		this.chart.dispose();
		this.chart = null;
	},
	methods: {
		async getAQIPoints() {
			const filterByActivePoints = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'disabled', op: 'eq', data: 0 },
					{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }
				]
			};
			//contans cn

			const body = {
				model: 'pmaqipoints',
				filter: filterByActivePoints,
				rows: 9999 //Para obtener todos los registros
			};

			//console.log(body);
			//console.log(filterByDate);

			const { data } = await search(body);
			console.log('RESULT (AQI points actives)');
			console.log(data.data);
			var activePoints = data.data;
			this.points = [];
			this.points.push({
				text: this.$t('aqi.choosePoint'),
				value: '0'
			});
			activePoints.forEach((item) => {
				var point = {
					text: item['name'],
					value: item['pmaqipointsid']
				};
				this.points.push(point);
			});
		}, //end getAQIPoints

		onResize() {
			if (this.chart) {
				this.chart.resize();
			}
		}, //end onResize

		getPoint(evt) {
			evt = evt.value;
			if (evt != 0) {
				console.log(evt);
				this.pointNoSelected = false;
				this.form.chooseDate = '0';
			} else {
				this.$puiNotify.info(this.$t('aqi.noValidPollutant'));
				this.pointNoSelected = true;
				this.form.chooseDate = '0';
			}
			if (!this.chart) {
				return;
			}
			this.chart.dispose();
			this.chart = null;
		}, //end getPoint

		changedLabel(evt) {
			// console.log(evt);
			evt = evt.value;
			if (evt != 0) {
				var currentDate = new Date();
				console.log(currentDate);

				currentDate.setDate(currentDate.getDate() - evt);
				console.log(currentDate);
				//console.log(currentDate.toISOString());

				var day = currentDate.getDate();

				var month = currentDate.getMonth() + 1;

				var year = currentDate.getFullYear();

				var filterDate = year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
				console.log(filterDate);
				this.getData(filterDate);
			} else {
				this.$puiNotify.info(this.$t('aqi.noValidOption'));
			}
		}, //end changedLabel

		async getData(date) {
			const filterByDate = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'date', op: 'ge', data: date },
					{ field: 'pmaqipointsid', op: 'eq', data: this.form.choosePoint.value },
					{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }
				]
			};
			//contains cn

			const body = {
				model: 'pmaqivalues',
				filter: filterByDate,
				rows: 9999 //Para obtener todos los registros
			};

			//console.log(body);
			//console.log(filterByDate);

			const { data } = await search(body);
			console.log('RESULT (AQI values)');
			console.log(data.data);

			if (data.data.length === 0) {
				console.log('Array is empty!');
				this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));

				if (!this.chart) {
					return;
				}
				this.chart.dispose();
				this.chart = null;
			} else {
				this.createData(data.data);
				//console.log('There are data');
			}
		},

		createData(data) {
			var xAxis = this.getXData(data);
			//console.log(xAxis);
			this.xData = xAxis;

			var series = [];
			data.forEach((item) => {
				//console.log(item);
				var objItem = item['pmaqidescriptionid'];

				series.push(objItem);
			});

			console.log(series);
			this.series = series;

			this.initChart();
		}, // end createData

		getXData(dataset) {
			var xAxis = [];

			dataset.forEach((item) => {
				//console.log(item);

				if (!xAxis.includes(item['date'].slice(0, 10))) {
					xAxis.push(item['date'].slice(0, 10));
				}
			});
			return xAxis;
		}, //end getXData

		initChart() {
			this.chart = echarts.init(this.$refs['echart']);
			//-----------------------
			var option;
			option = {
				tooltip: {
					trigger: 'axis'
				},
				grid: {
					left: '5%',
					right: '15%',
					top: '10%'
				},
				xAxis: {
					data: this.xData
				},
				yAxis: {},
				/*toolbox: {
					right: 10,
					feature: {
						dataZoom: {
							yAxisIndex: 'none'
						},
						restore: {},
						saveAsImage: {}
					}
				},*/
				/*dataZoom: [
					{
						startValue: '2014-06-01'
					},
					{
						type: 'inside'
					}
				],*/
				visualMap: {
					top: 50,
					right: 10,
					/*inRange: {
						color: ['#38A2CE', '#32A15E', '#F1E549', '#C83441', '#6E161D', '#A25BA4'] //From smaller to bigger value ->
					},*/
					pieces: [
						{
							gt: 0,
							lte: 1,
							color: '#38A2CE'
						},
						{
							gt: 1,
							lte: 2,
							color: '#32A15E'
						},
						{
							gt: 2,
							lte: 3,
							color: '#F1E549'
						},
						{
							gt: 3,
							lte: 4,
							color: '#C83441'
						},
						{
							gt: 4,
							lte: 5,
							color: '#6E161D'
						},
						{
							gt: 5,
							lte: 6,
							color: '#A25BA4'
						}
					],
					outOfRange: {
						color: '#6f7480'
					}
				},
				series: {
					name: 'AQI evolution',
					type: 'line',
					data: this.series,
					markLine: {
						silent: true,
						lineStyle: {
							color: '#333'
						},
						data: [
							{
								yAxis: 1
							},
							{
								yAxis: 2
							},
							{
								yAxis: 3
							},
							{
								yAxis: 4
							},
							{
								yAxis: 5
							},
							{
								yAxis: 6
							}
						]
					}
				}
			};

			//-----------------------
			//this.chart.setOption(option);
			option && this.chart.setOption(option);
		}
	}
};
</script>

<style scoped>
.topRight {
	position: absolute;
	top: 0px;
	right: 10px;
}

.topLeft {
	position: absolute;
	top: 0px;
	left: 10px;
}
</style>
