<template>
	<div class="pt-0 w-100">
		<v-form v-model="pollutantsForm" ref="form" style="max-height: 91px; overflow: hidden auto">
			<div row wrap>
				<v-layout wrap class="px-3">
					<!-- Pollutants -->
					<v-flex xs12 md6>
						<pui-select
							:label="$t('aqi.pollutant')"
							v-model="form.choosePollutant"
							:items="pollutants"
							:itemValue="['value']"
							itemText="text"
							toplabel
							required
							@input="getPollutant"
						>
						</pui-select>
					</v-flex>
					<!-- Date interval -->
					<v-flex xs12 md6>
						<pui-select
							:label="$t('aqi.dateInterval')"
							v-model="form.chooseDate"
							:items="filter_options"
							:itemValue="['value']"
							itemText="text"
							toplabel
							required
							@input="changedLabel"
							:disabled="pollutantNoSelected"
						>
						</pui-select>
					</v-flex>
				</v-layout>
			</div>
		</v-form>

		<!--  echarts component -->
		<div ref="echart" class="d-flex justify-center align-center" style="height: 530px; width: 100%" />
	</div>
</template>

<script>
import * as echarts from 'echarts';

import { search } from '@/api/common';

export default {
	name: 'o3_concentration',
	data() {
		return {
			width: '100%',
			height: '100%',
			chart: null,
			pollutantNoSelected: true,
			form: {
				chooseDate: '0',
				choosePollutant: '0'
			},
			filter_options: [
				{ text: this.$t('aqi.chooseInterval'), value: '0' },
				{ text: this.$t('aqi.last24h'), value: '1' },
				{ text: this.$t('aqi.last48h'), value: '2' },
				{ text: this.$t('aqi.lastWeek'), value: '7' },
				{ text: this.$t('aqi.last15D'), value: '15' },
				{ text: this.$t('aqi.lastMonth'), value: '30' }
			],
			pollutants: [
				{ text: this.$t('aqi.choosePollutant'), value: '0' },
				{ text: this.$t('aqi.o3_concentration') + ' (ppm)', value: 'o3conc' },
				{ text: this.$t('aqi.no2_concentration') + ' (µg/m3)', value: 'no2conc' },
				{ text: this.$t('aqi.so2_concentration') + ' (µg/m3)', value: 'so2conc' },
				{ text: this.$t('aqi.pm25_concentration') + ' (µg/m3)', value: 'pm2p5conc' },
				{ text: this.$t('aqi.pm10_concentration') + ' (µg/m3)', value: 'pm10conc' }
			],
			pollutantsForm: false,
			xData: [],
			series: []
		};
	},
	components: {},
	props: {
		resized: {
			type: Object,
			required: false
		}
	},
	computed: {
		defaultWidth() {
			if (this.resized && this.resized.width) {
				//return `${this.resized.width - 50}px`;
			}
			return '100%';
		},
		defaultHeight() {
			if (this.resized && this.resized.height) {
				return `${this.resized.height - 50}px`;
			}
			return '100%';
		}
	},

	watch: {
		resized: {
			handler() {
				this.onResize();
			}
		},
		'form.choosePollutant': function (newValue, oldValue) {
			if (newValue != oldValue) {
				this.initChart();
			}
		}
	},

	beforeDestroy() {
		if (!this.chart) {
			return;
		}
		this.chart.dispose();
		this.chart = null;
	},
	methods: {
		onResize() {
			if (this.chart) {
				this.chart.resize();
			}
		}, //end onResize

		getPollutant(evt) {
			if (evt != 0) {
				console.log(evt);
				this.pollutantNoSelected = false;
				this.form.chooseDate = '0';
			} else {
				this.$puiNotify.info(this.$t('aqi.noValidPollutant'));
				this.pollutantNoSelected = true;
				this.form.chooseDate = '0';
			}
			if (!this.chart) {
				return;
			}
			this.chart.dispose();
			this.chart = null;
		}, //end getPollutant

		changedLabel(evt) {
			evt = evt.value;
			if (evt != 0) {
				var currentDate = new Date();
				console.log(currentDate);

				currentDate.setDate(currentDate.getDate() - evt);
				console.log(currentDate);
				//console.log(currentDate.toISOString());

				var day = currentDate.getDate();

				var month = currentDate.getMonth() + 1;

				var year = currentDate.getFullYear();

				var filterDate = year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
				console.log(filterDate);
				this.getData(filterDate);
			} else {
				this.$puiNotify.info(this.$t('aqi.noValidOption'));
			}
		}, //end changedLabel

		async getData(date) {
			const filterByDate = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'date', op: 'ge', data: date },
					{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }
				]
			};
			//contains cn

			const body = {
				model: 'pmaqivalues',
				filter: filterByDate,
				rows: 9999 //Para obtener todos los registros
			};

			//console.log(body);
			//console.log(filterByDate);

			const { data } = await search(body);
			console.log('RESULT (AQI values)');
			console.log(data.data);

			if (data.data.length === 0) {
				console.log('Array is empty!');
				this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
				if (!this.chart) {
					return;
				}
				this.chart.dispose();
				this.chart = null;
			} else {
				this.createData(data.data);
			}
		},

		createData(data) {
			var xAxis = this.getXData(data);
			//console.log(xAxis);
			this.xData = xAxis;

			var dataGroupedByPoint = this.groupByPoint(data);
			console.log('Data grouped by Points');
			console.log(dataGroupedByPoint);

			//console.log(this.form.choosePollutant);

			var series = [];
			dataGroupedByPoint.forEach((item) => {
				//console.log(item);
				var objItem = {
					name: item['locationname'],
					type: 'bar',
					barGap: 0,
					label: {},
					emphasis: {
						focus: 'series'
					},
					data: []
				};
				item.pollutants.forEach((pollutant) => {
					objItem.data.push(pollutant[this.form.choosePollutant.value].toFixed(2));
				});
				series.push(objItem);
			});

			//console.log(series);
			this.series = series;

			this.initChart();
		}, // end createData

		getXData(dataset) {
			var xAxis = [];

			dataset.forEach((item) => {
				//console.log(item);

				if (!xAxis.includes(item['date'].slice(0, 10))) {
					xAxis.push(item['date'].slice(0, 10));
				}
			});
			return xAxis;
		}, //end getXData

		groupByPoint(dataset) {
			const object = dataset.reduce((r, { locationname, ...rest }) => {
				if (!r[locationname]) r[locationname] = { locationname, pollutants: [rest] };
				else r[locationname].pollutants.push(rest);
				return r;
			}, {});

			const result = Object.values(object);
			return result;
		}, //end groupByPoint

		initChart() {
			this.chart = echarts.init(this.$refs['echart']);

			//-----------------------
			var app = {};

			const posList = [
				'left',
				'right',
				'top',
				'bottom',
				'inside',
				'insideTop',
				'insideLeft',
				'insideRight',
				'insideBottom',
				'insideTopLeft',
				'insideTopRight',
				'insideBottomLeft',
				'insideBottomRight'
			];
			app.configParameters = {
				rotate: {
					min: -90,
					max: 90
				},
				align: {
					options: {
						left: 'left',
						center: 'center',
						right: 'right'
					}
				},
				verticalAlign: {
					options: {
						top: 'top',
						middle: 'middle',
						bottom: 'bottom'
					}
				},
				position: {
					options: posList.reduce(function (map, pos) {
						map[pos] = pos;
						return map;
					}, {})
				},
				distance: {
					min: 0,
					max: 100
				}
			};

			app.config = {
				rotate: 90,
				align: 'left',
				verticalAlign: 'middle',
				position: 'insideBottom',
				distance: 15,
				onChange: function () {
					const labelOption = {
						rotate: app.config.rotate,
						align: app.config.align,
						verticalAlign: app.config.verticalAlign,
						position: app.config.position,
						distance: app.config.distance
					};
					this.chart.setOption({
						series: [
							{
								label: labelOption
							},
							{
								label: labelOption
							},
							{
								label: labelOption
							},
							{
								label: labelOption
							}
						]
					});
				}
			};
			const labelOption = {
				show: true,
				position: app.config.position,
				distance: app.config.distance,
				align: app.config.align,
				verticalAlign: app.config.verticalAlign,
				rotate: app.config.rotate,
				formatter: '{c}  {name|{a}}',
				fontSize: 16,
				rich: {
					name: {}
				}
			};

			// eslint-disable-next-line no-unused-vars
			this.series.forEach((item) => {
				this.series.label = labelOption;
			});
			//-----------------
			var option;

			option = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				} /*,
				legend: {
					data: ['Forest', 'Steppe', 'Desert', 'Wetland']
				}*/,
				xAxis: [
					{
						type: 'category',
						axisTick: { show: false },
						data: this.xData
					}
				],
				yAxis: [
					{
						type: 'value'
					}
				],
				series: this.series
			};

			//-----------------------
			option && this.chart.setOption(option);
			//this.chart.setOption(option);
		}
	}
};
</script>

<style scoped>
.topRight {
	position: absolute;
	top: 0px;
	right: 10px;
}

.topLeft {
	position: absolute;
	top: 0px;
	left: 10px;
}
</style>
