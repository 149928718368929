<template>
	<v-tooltip right nudge-right="10" :color="primaryColor" max-width="500" :disabled="!tooltipTitle">
		<template v-slot:activator="{ on, attrs }">
			<div class="layer-list-tooltip" :class="{ 'border-bottom': borderBottom }">
				<v-list-group
					sub-group
					@click.native.stop="opened = !opened"
					:value="opened"
					:ripple="false"
					:append-icon="undefined"
					active-class="active"
					v-bind="attrs"
					v-on="on"
					:class="noPadding ? 'no-padding' : false"
				>
					<template #prependIcon>
						<v-icon v-if="!!$slots.default" size="13" class="high">
							fal
							{{ opened ? 'fa-minus' : 'fa-plus' }}
						</v-icon>
						<div v-else />
					</template>

					<template #activator>
						<v-list-item-title
							class="layer-item color-black fw-semi fs-regular ml-n4 text-capitalize"
							:class="{ disabled: computedDisabled }"
							>{{ title }} <i v-if="tooltipTitle" class="fak fa-line-info-2 ps-1 fs-sm fw-regular"></i>
						</v-list-item-title>
					</template>

					<template #appendIcon>
						<div class="d-flex align-center">
							<pui-switch
								:disabled="computedDisabled"
								color="blue darken-1"
								class="mt-0 pt-0"
								v-model="computedActive"
								@click.native.stop=""
								@input="(value) => (computedActive = value)"
							></pui-switch>

							<!-- file icon for downloading predictions & real values -->
							<!--<v-btn
								v-if="(title == 'CHL' || ((title == 'TUR' || title == 'SPM') && checkDate(date))) && organization == 'VIG'"
								text
								icon
								@click="downloadFile(title, date, organization)"
							>
								<img :src="jsonFile_icon" style="transform: scale(0.7); width: 50px" />
							</v-btn>-->
						</div>
					</template>
				</v-list-group>
			</div>
		</template>
		<span class="fs-regular">{{ tooltipTitle }}</span>
	</v-tooltip>
</template>

<script>
import jsonFileImage from '@/assets/images/json_file.png';

import { download } from '@/api/file_API';

export default {
	data() {
		return {
			jsonFile_icon: jsonFileImage,
			primaryColor: this.$store.state.global.primaryColor + 'D9'
		};
	},
	props: {
		value: {
			type: Boolean,
			required: true
		},

		active: {
			type: Boolean,
			required: true
		},

		title: {
			type: String,
			required: false
		},

		organization: {
			type: String,
			required: false
		},

		date: {
			type: String,
			required: false
		},

		borderBottom: {
			type: Boolean,
			default: false
		},

		disabledItem: {
			type: Boolean,
			required: false
		},
		disabledDataSource: {
			type: Boolean,
			required: false
		},
		tooltipTitle: {
			type: String,
			required: false
		},
		noPadding: {
			type: Boolean,
			required: false
		}
	},
	watch: {
		active(newValue, oldValue) {
			//console.log(`LayerListTooltip 'active' changed from ${oldValue} to ${newValue}`);
			//this.$emit('update:active', newValue);
			if (newValue) {
				//console.log('newValue true');
			}
			if (oldValue) {
				//console.log('oldValue true');
			}
		}
	},
	computed: {
		opened: {
			get() {
				return this.value;
			},

			set(value) {
				this.$emit('input', value);
			}
		},

		computedActive: {
			get() {
				//console.log(this.active);
				return this.active;
			},

			set(value) {
				this.$emit('update:active', value);
			}
		},
		computedDisabled() {
			return !this.disabledItem && !this.disabledDataSource ? false : true;
		}
	},
	methods: {
		async downloadFile(pollutant, date, organization) {
			var name = date + '_' + organization + '_' + pollutant;
			var body = {
				fileName: name,
				ext: 'json'
			};
			await download(body).then(
				(response) => {
					this.downloadTxt(name + '.json', response.message);
					var titleDialog = this.$t('common.file');
					titleDialog += ' ' + name + '.json' + ' ';
					titleDialog += this.$t('common.downloaded');
					this.$puiNotify.success(titleDialog, this.$t('common.success'));
				},
				// eslint-disable-next-line no-unused-vars
				(error) => {
					this.$puiNotify.error(this.$t('common.reviewFileAPI'), this.$t('common.error'));
				}
			);
		}, // end downloadFile

		downloadTxt(filename, text) {
			var element = document.createElement('a');
			//element.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(text, null, 4)));
			//element.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(text)));
			element.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(text));
			element.setAttribute('download', filename);
			//element.setAttribute('target', '_blank');

			element.style.display = 'none';
			document.body.appendChild(element);

			element.click();

			document.body.removeChild(element);
		},

		checkDate(date) {
			var parts_compare = '2023-08-30'.split('-');
			var compareDate = new Date(parts_compare[0], parts_compare[1] - 1, parts_compare[2]);

			var parts = date.split('-');
			var realDate = new Date(parts[0], parts[1] - 1, parts[2]);

			if (realDate >= compareDate) {
				return true;
			} else {
				return false;
			}
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../../../styles/eiffel-variables.pcss';

.layer-list-tooltip {
	& .border-bottom {
		border-bottom: 1px #fff solid;
	}

	& .high {
		color: var(--high) !important;
	}

	& .fw-bold {
		font-weight: 700;
	}

	& .disabled {
		color: var(--moderate) !important;
	}

	& .no-padding > .v-list-item {
		padding: 0px !important;
	}
}
</style>
